<template>
  <div class="columns is-multiline">
    <NewsItemCard
      v-for="newsItem in newsItems"
      :key="newsItem.id"
      :newsItem="newsItem">
    </NewsItemCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import NewsItemCard from './NewsItemCard.vue'
import NewsItemModel from '../models/newsItemModel'

export default {
  components: {
    NewsItemCard
  },

  data () {
    return {
      ads: [],
      adIndexes: []
    }
  },

  computed: {
    ...mapGetters({
      language: 'ui/language'
    }),

    newsItems () {
      let newsItems = this.$store.getters['news/newsItems'].slice(0) // Don't modify Vuex data

      for (let i = 0; i < this.ads.length; i++) {
        newsItems.splice(this.adIndexes[i], 0, this.ads[i])
      }

      return newsItems
    }
  },

  created () {
    this.createAds()
  },

  methods: {
    createAds () {
      const sponsoredPosts = this.getLanguageSpecificSponsoredPosts()

      sponsoredPosts.forEach(sponsoredPost => {
        const ad = new NewsItemModel({
          id: sponsoredPost.targetUrl, // Fake a unique id to minimize rerendering in Vue
          title: sponsoredPost.title,
          description: sponsoredPost.content,
          source: sponsoredPost.customer,
          link: sponsoredPost.targetUrl,
          imageUrl: sponsoredPost.imageUrl,
          publishedAt: sponsoredPost.startDate,
          sponsored: true
        })

        const adIndex = this.getRandomAdIndex()

        this.ads.push(ad)
        this.adIndexes.push(adIndex)
      })
    },

    getLanguageSpecificSponsoredPosts () {
      return this.$store.getters['advertisement/sponsoredPosts'].filter(sponsoredPost => {
        return sponsoredPost.language === null || sponsoredPost.language === this.language
      })
    },

    getRandomAdIndex () {
      const newsItems = this.$store.getters['news/newsItems']

      const min = Math.ceil(1)
      const max = Math.floor(Math.min(newsItems.length, 10))

      return Math.floor(Math.random() * (max - min)) + min
    }
  }
}
</script>

<template>
  <div class="page page-news">
    <Titlebar>
      <h5 slot="title" class="title is-5 is-marginless">
        {{ $t('news.title') }}
      </h5>
    </Titlebar>

    <div class="container is-fluid">
      <NewsItemGrid v-if="hasData" />

      <div v-else class="columns is-multiline">
        <NewsItemPlaceholderCard v-for="index in 10" :key="index" />
      </div>

      <div class="columns news-show-more push-top-xl">
        <div class="column is-one-third is-offset-one-third">
          <Button
            v-if="loadMoreVisible"
            type="secondary"
            :title="$t('news.showMore')"
            :loading="loading"
            fullwidth
            class="push-bottom"
            @click="readNewsItems">
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsItemGrid from './NewsItemGrid.vue'
import NewsItemPlaceholderCard from './NewsItemPlaceholderCard.vue'

import { mapGetters } from 'vuex'

export default {
  components: {
    NewsItemGrid,
    NewsItemPlaceholderCard
  },

  data () {
    return {
      loadMoreVisible: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      language: 'ui/language'
    }),

    hasData () {
      const newsItems = this.$store.getters['news/newsItems']
      const sponsoredPosts = this.$store.getters['advertisement/sponsoredPosts']

      return newsItems !== null && sponsoredPosts !== null
    }
  },

  watch: {
    language () {
      this.read()
    }
  },

  mounted () {
    this.read()
  },

  methods: {
    read () {
      this.$store.commit('news/clear')
      this.readNewsItems()
    },

    async readNewsItems () {
      this.loading = true

      try {
        const newsItems = await this.$store.dispatch('news/getAll', 20)
        this.loadMoreVisible = newsItems.length === 20
      } catch (error) {
        this.$notification.danger(this.$i18n.t('news.loadFailedErrorMessage'))
      }

      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-news {
  display: block !important;
  overflow-y: auto;
}
</style>

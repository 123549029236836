<template>
  <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-third-widescreen is-one-quarter-fullhd">
    <div :class="{ 'sponsored-item': newsItem.sponsored }" class="card news-card">
      <div v-if="newsItem.sponsored" class="tag is-info is-light">
        {{ $t('news.ad') }}
      </div>

      <div v-if="newsItem.imageUrl !== null" class="card-image">
        <figure class="image is-16by9" :style="imageSource"></figure>
      </div>

      <div class="card-content">
        <h5 class="title is-5">{{ newsItem.title }}</h5>
        <div class="content" v-html="content"></div>
      </div>

      <div class="default-spacing is-paddingless-top">
        <a :href="newsItem.link" target="_blank" class="button is-primary is-fullwidth text-is-ellipsis">
          {{ readMore }}
        </a>
      </div>

      <footer class="card-footer">
        <div class="card-footer-item is-marginless">
          <span v-if="!newsItem.sponsored">{{ publishedAt }}</span>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    newsItem: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      sources: {
        'WeHunt': 'WeHunt',
        'SvenskJakt': 'Svensk Jakt',
        'Jaktjournalen': 'Jaktjournalen',
        'Vildmarken': 'Vildmarken',
        'NJFF': 'NJFF',
        'DJ': 'DJ',
        'ShootingUK': 'Shooting UK',
        'TheField': 'The Field',
        'TheGuardian': 'The Guardian'
      }
    }
  },

  computed: {
    publishedAt () {
      return this.$utils.capitalize(this.$dayjs(this.newsItem.publishedAt).format('ddd DD MMM HH:mm'))
    },

    imageSource () {
      return {
        'background-image': `url(${this.newsItem.imageUrl})`
      }
    },

    content () {
      /*
      description is markup and not pure text. Truncating the description like this can truncate an element in the middle
      and cause unknown and weird side-effects. For now just show the content as is.
      */
      /*
      return this.newsItem.sponsored
        ? this.newsItem.description
        : this.truncate(this.newsItem.description, 250)
      */

      return this.newsItem.description
    },

    readMore () {
      return this.newsItem.sponsored
        ? this.$t('news.readMore')
        : this.$t('news.readFullArticle', { source: this.sourceName() })
    }
  },

  methods: {
    sourceName () {
      // Sponsored posts contain the actual name in 'source', normal news items contain a constant that must be localized
      return this.newsItem.sponsored ? this.newsItem.source : this.sources[this.newsItem.source]
    },

    truncate (string, length) {
      return (string.length > length) ? string.substr(0, length - 1) + '&hellip;' : string
    }
  }
}
</script>

<style lang="scss">
.news-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
  background: white;
  border-radius: 6px;
  /*box-shadow: 0 0.5em 1em -0.125em rgba(40, 40, 40, 0.1),
    0 0px 0 1px rgba(40, 40, 40, 0.02);*/

  .tag {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: 1;
  }

  .card-image {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    overflow: hidden;

    .image {
      // We used to have object-fit: cover; But it doesn't work in IE
      background-size: cover;
      background-position: center;
    }
  }

  .card-content {
    flex-grow: 1;

    .title {
      line-height: 1.5;
    }

    .content {
      overflow: hidden;
      margin-bottom: 0 !important;

      p {
        display: none;
      }

      p:first-of-type {
        display: block;
      }
    }
  }

  .card-footer {
    height: 3rem;
  }
}
</style>
